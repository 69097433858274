@import "~@blueprintjs/core/lib/css/blueprint.css";
@import "~@blueprintjs/icons/lib/css/blueprint-icons.css";
@import "~@blueprintjs/core/lib/scss/variables";
@import "~@blueprintjs/table/lib/css/table.css";

$ft_bg:#F2F2F2;
$ft_black:#333333;
$ft_gray:#AAAAAA;
$ft_light_gray:#EEEEEE;
$ft_blue:$blue1;
$ft_box_border:#DDDDDD;
$ft_box_radius:5px;

$ft_title1:24px;
$ft_title2:18px;
$ft_title3:16px;
$ft_title4:14px;
$ft_title5:12px;
$ft_title6:9px;

$ft_normal_text: #666666;

$ft_title_weight:400;

@mixin md-style
{
    line-height: 1.8;
    font-size: 16px;
    color:#666;
    // letter-spacing: 0.5px;

    h1 {font-size:24px; color:$ft_blue;margin-top:30px;  }
    h2 {font-size:20px; color:#000;margin-top:20px;margin-bottom:10px;}
    h3 {font-size:18px; color:#000;margin-top:20px; }
    h4 {font-size:16px;  color:#000;margin-top:20px;}
    h5 {font-size:16px;  color:#000;margin-top:20px;}
    h6 {font-size:16px;  color:#000;margin-top:20px;}

    ul,ol
    {
        padding:0px;
        li
        {
            margin-left:10px;
            list-style-position: inside;
            list-style-type: simp-chinese-informal;
            ol li
            {
                list-style-type: decimal;
            }
        }        
    }

    ul li 
    {
        list-style: none;

        &::before
        {
            // content:'◎○♤☆§※';
            content:'🎈';
            margin-right: 5px;
        }
    }

    strong
    {
        font-weight: normal;
        // border-bottom: 2px dotted $ft_gray ;
        position: relative;
        &:after
        {
            display: block;
            background-color: yellow;
            position: absolute;
            left:0px;
            right:0px;
            bottom: 0px;
            height: 10px;
            overflow: hidden;
            white-space: nowrap;
            border-radius: 3px;
            content: "";
            z-index: -1;
        }
    }

    
    

    
    a
    {
        color:#0866a0;
        text-decoration: none;
        &:hover
        {
            padding-bottom: 3px;
            border-bottom: 1px solid #0866a0;
        }
    }

    img
    {
        border-radius: 5px;
        // border:1px solid #eee;
        max-width: 100%;

        &:hover
        {
            @extend %shadow-heavy;
        }
    }
}

html
{
    background-color: #f8f8f9;
}

body
{
    margin: 0px;
    font-family: "Chinese Quote", -apple-system, BlinkMacSystemFont, "Segoe UI", "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", "Helvetica Neue", Helvetica, Arial, sans-serif,"Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
}

h1,h2,h3,h4,h5
{
    font-weight: 400;
    font-family: "Chinese Quote", -apple-system, BlinkMacSystemFont, "Segoe UI", "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", "Helvetica Neue", Helvetica, Arial, sans-serif,"Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
}

.std-overlay
{
    .overbox
    {
        width:100%;
        height:100%;
        display: flex;
        align-items: center;
        justify-content: center;
        .centerbox
        {
            max-width:400px;
            min-width:400px;
            min-height:100px;
            background-color: white;
            border-radius: 5px;
            padding:20px;
        }
    }
}

.std-form-actionbar
{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}



.center-box
{
    // border: 1px solid red;
    max-width:900px;
    min-height:600px;
    // margin-left: auto;
    // margin-right: auto;
    flex:1;
    justify-content:center;
    align-items:center;
}

.center-wrapper
{
    display: flex;
    justify-content:center;
    align-items:center;
    height: 99vh;
}

.center-v
{
    display: flex;
    align-items:center; 
}

.center-h
{
    display: flex;
    justify-content:center;
}

%center-h
{
    justify-content:center;
}

// ======== header =====
.std-header
{
    position: fixed;
    width:100%;
    z-index: $pt-z-index-content;
    
    @extend %shadow;
    
    background-color: rgba(255, 255, 255, .9);
    height:60px;

    h1.title
    {
        color:$ft_blue;
        font-size: $ft_title1;
    }

    .header-box
    {
        >*
        {
            display: flex;
            align-items:center; 
        }
        flex:1;
        display: flex;
        align-items:center;
        flex-direction: row;

        .title
        {
            flex:1;
            min-width: 150px;
            img
            {
                max-height:50px;
                max-width:150px;
            }
        }
        .menu
        {
            flex:6;
        }
        .action
        {
            flex:1;
            display: flex;
            justify-content: flex-end;
        }
    }
}

.wrapper
{
    max-width: 900px;
    margin-left: auto;
    margin-right: auto;
    height: 100%;
}


.left-logo-bg
{
    &:hover
    {
        background-position: 0% -60%;
    }
}

.std-box
{
    
    position: relative;
    top:60px;
    .page-title
    {
        padding-top:20px;
        padding-bottom:20px;
        span
        {
            font-size: $ft_title2;
            color:$ft_gray;
            font-weight:$ft_title_weight;
            margin-right: 10px;
            &.active
            {
                color:$ft_blue;
            }
        }
    }

    .row
    {
        display: flex;
        flex-direction: row;
        .main
        {
            flex:8; 
            margin-bottom:40px;   
        }
        .side
        {
            min-width: 240px;
            flex:2;
            padding-left:20px;
            margin-bottom:40px; 
        }

        .bottom
        {
            display: none;
        }

        .wrapper:not(:empty)
        {
            &:not(:hover)
            {
                @extend %shadow;
            }
            &:hover
            {
                @extend %shadow-heavy;
            }
    
            background-color: white;
            border: 1px solid $ft_box_border;
            border-radius: $ft_box_radius;
            padding:20px;
        }
    }

    
}

.order-box
{
    position: relative;
    top:20px;
    background-color: white;
    padding:20px;
    border: 1px solid $ft_box_border;
    border-radius: $ft_box_radius;
    &:not(:hover)
    {
        @extend %shadow;
    }
    &:hover
    {
        @extend %shadow-heavy;
    }

    .item
    {
        padding:10px 0px 10px 0px;
        &:not(:last-child)
        {
            border-bottom: 1px solid $ft_box_border;
        }

        .row
        {
            color:$ft_gray;
            display: flex;
            > *
            {
                margin-bottom: 10px;
            }
            .price
            {
                flex:1;
            }
            .time
            {
                flex:1;
            }
            .status
            {
                flex:1;
                .status-icon
                {
                    span
                    {
                        font-size: $ft_title5;
                        background-color: $ft_blue;
                        color:white;
                        padding:5px;
                        border-radius: 2px;
                    }
                }
            }
        }
    }
}

.std-top-menu
{
    margin: 0px;
    padding:0px;
    li
    {
        display: inline-block;
        color:$ft_gray;
        font-size: $ft_title3;
        font-weight: $ft_title_weight;
        &:not(:last-child)::after
        {
            content: '·';
            color: $ft_light_gray;
            margin-left:3px;
            margin-right:3px;
        }
        a
        {
            color:$ft_gray;
        }
        a.actived
        {
            color:$ft_blue!important;
        }
    } 
}

.avatar-icon
{
    max-width: 32px;
    max-height: 32px;
    border-radius: 100%;
}

.avatar-name
{
    font-size: $ft_title4;
    color:$ft_gray;
    margin-left:10px;
}

.info-box
{
    color:$ft_gray;
    @extend %center-h;
    display: flex;
    flex-direction: column;
}


// ======== login =======
.login-box
{
    background-color: white;
    border: 1px solid $ft_box_border;
    border-radius: $ft_box_radius;
    padding:20px;
    min-height: 400px;
    text-align: center;
    .qrcode
    {
        width:240px;
    }
}

.login-logo
{
    position: relative;
    top:40px;
    left:320px;

}

%shadow
{
    box-shadow: 0px 3px 6px rgba(0, 0, 0, .16);
}

%shadow-heavy
{
    box-shadow: 3px 6px 10px rgba(0, 0, 0, .16);
    transition: box-shadow 0.5s ease-in-out;
}

.no-data
{
    width:400px;
    height:240px;
    margin-left:auto;
    margin-right:auto;
    margin-top:100px;
    margin-bottom:100px;
    border:1px solid $ft_box_border;
    border-radius: $ft_box_radius;
    position: relative;
    justify-content: center;
    display: flex;
    .man
    {
        width:160px;
        position: absolute;
        bottom:0px;
        right:10px;
    }
    .text
    {
        margin-top:40px;
        font-size:$ft_title1;
        font-weight: 300;
        color:$ft_gray;
    }
}

.std-area
{
    width:100%;
    min-height: 120px;

    &.long
    {
        min-height: 400px;
    }
}

.std-row
{
    display: flex;
    flex-direction: row;
}

.image-select-row
{
    align-items: flex-end;
}

.row-half
{
    display: flex;
    flex-direction: row;
    >*
    {
        display: flex;
        flex:1;
        &:last-child
        {
            justify-content: flex-end;
        }
    }
}

.fs-editor
{
    display: flex;
    width:100%;
    height:100%;
    
    .fs-edit
    {
        flex:1;
        background-color: white;
        .toolbar
        {
            display: flex;
            flex-direction: row;
            margin-bottom: 2px;
            margin-top: 2px;
            height: 35px;
            padding:5px;
            align-items: center;
        }

        .fs-textarea
        {
            width:100%;
            height: calc( 100vh - 47px );
            border: 0px;

        }


    }

    .fs-preview
    {
        flex:1;
        background-color: $ft_bg;
        padding:40px;
        height: 100%;
        overflow-y: scroll;

        @include md-style;
    }
}

.project-box-item
{
    border-bottom: 1px solid $ft_box_border;
    position: relative;
    left:-20px;
    width:calc( 100% + 40px );
    padding:20px;

    &:last-child
    {
        border:0px;
    }
    .row
    {
       

        display: flex;
        flex-direction: row;

        .actionbox
        {
            flex:1;
            max-width:200px;
            margin:10px;
            display:flex;
            justify-content: flex-end;
            align-items: center;

            .action:not(:first-child)
            {
                margin-left:10px;
            }
        }

        .desp
        {
            padding-left:10px;
            padding-right:10px;
            flex:1;
            .name
            {
                color:$ft_blue;
                font-size:$ft_title1;
                margin-bottom: 5px;
            }
            .title
            {
                color:$ft_gray;
                font-size: $ft_title3;
            }
            .priceline
            {
                margin-top:10px;
                margin-bottom: 5px;
                display: flex;
                flex-direction: row;
                align-items: center;
                
                > *
                {
                    margin-right:5px;
                }
                .price
                {
                    color:$ft_blue;
                    font-family: Georgia, Times, 'Times New Roman', serif; 
                }
                .explain
                {
                    color:$ft_gray;
                }
                .price.discount
                {
                    font-size:$ft_title2;
                    color:$ft_gray;
                    font-family: Georgia, Times, 'Times New Roman', serif;
                    text-decoration:line-through; 
                }
                
                .member_count
                {
                    color:$ft_gray;
                }
            }

            .discount_price_span
            {
                padding:3px;
                padding-left:6px;
                padding-right:6px;
                color:white;
                background-color: $blue5;
                border-radius: 2px;
                font-size: 12px;
            }

            
        }
    }

    .updating-desp
    {
        margin-top:15px;
        color: $ft_blue;
        font-size:$ft_title2;
    }
    

    
}



.landing-box
{
    .subtitle
    {
        color:$ft_gray;
    }

    .desp
    {
       @include  md-style;
    //    p:first-letter{
    //     font-size:20px;
    //    }


    }

    .code
    {
        border:1px solid #eee;
        background-color: white;
        padding:10px;
        margin-top:10px;
        margin-bottom:10px;
        border-radius: 5px;
    }

    video
    {
        border-radius: 5px;
        border:1px solid #eee;
        &:hover
        {
            @extend %shadow-heavy;
        }
        margin-top:10px;
        margin-bottom: 10px;
    }

    hr
    {
        width: 30%;
        border:2px solid #eee;
        margin-top:40px;
        margin-bottom: 40px;
    }

    
}

.side .landing
{
    margin-top: 120px;
    max-width:250px;

    &.price-box
    {
        position: fixed;
        height:auto;
        width: auto;
        .price
        {
            font-family: Georgia, 'Times New Roman', Times, serif;
            margin-top: 0px;
            margin-bottom: 10px;
            color:$ft_blue;
        }

        .divider
        {
            border-bottom: 1px solid $ft_box_border;
            position: relative;
            left:-20px;
            width:calc( 100% + 40px );
            margin-top:10px;
            margin-bottom:10px;
        }

        .item-name
        {
            font-size: 18px;
            color:black;
        }

        .item-detail
        {
            margin-top: 5px;
            margin-bottom: 10px;
        }

        .item-price
        {
            font-family: Georgia, 'Times New Roman', Times, serif;
            margin-bottom: 10px;
            margin-top: 10px;
            color:$ft_blue;
            font-size: 32px;
            text-align: center;

            span
            {
                margin-left: 10px;
                font-size: 14px;
                color: $ft_gray;
            }
        }
    }
}

.mt5
{
    margin-top:5px;
}

.mt10
{
    margin-top:10px;
}

.qrdiv
{
    width:240px;
    height:240px;
    background-color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    h3{ margin-top:0px; }
    left: calc( 50% - 120px );
    top: calc( 50% - 120px);
    border:1px solid #eee;
    border-radius: 5px;
}

.pay-box
{
    width:100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    
    .paid-notice
    {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;

        img
        {
            margin-top: 10px;
            max-width: 80vw;
        }

        
    }

    .btn
    {
        margin-bottom: 20px;
    }

    .preload
    {
        width:0px;
        height:0px;
    }
}

@media (max-width: 700px) 
{ 
    .header-box
    {
        padding-left:5px;
        padding-right:5px;
    }
    
    .std-box
    {
        .row
        {
            flex-direction: column;
            .main
            {
                padding:10px;
                padding-left:20px;
                padding-right:20px;
            }

            .side
            {
                padding-left:0px;
                .landing
                {
                    max-width: 90%;
                    margin-top:0px;
                }
                
                .price-box
                {
                    position: relative;
                    width:98%;
                }
            }

        }
    }
}

.std-hr
{
    border-top:1px solid $ft_light_gray;
    margin-top: 20px;
    padding-bottom: 20px;
}

.form-div
{
    position: relative;
    left:-20px;
    width:calc( 100% + 40px );
}

.loading-box
{
    display: flex;
    justify-content:center;
    align-items:center;
    height: 100vh;
    .pbar
    {
        width:100px;
    }
}

.stock-table
{
    .id-col
    {
        // max-width:60px;
    }
}

.stock-table
{
    border-top: 1px solid #f2f2f2;
    width: calc( 100% + 40px );
    position: relative;
    left:-20px;
    // width:100%;

    thead *, tfoot *
    {
        color:#ccc!important;
    }

   td
   {
        vertical-align: middle!important;
        font-size: 14px;
        .stock-icon
        {
            svg
            {
              max-width: 14px;
              max-height: 14px;
            }
        }
        
        &.actions > *
        {
            margin-left:10px;
        }

        &.center
        {
            text-align: center;
        }
   }
}

.stock-clean
{
    margin-bottom: 0px!important;
    // padding-left:0px!important;
}

.stock-checkbox-col
{
    max-width:50px;
}

.stock-sidemenu
{
    font-size:16px;

    

    display: flex;
    align-items: center;

}

.notice-box
{
    padding: 10px;
    line-height: 1.8;
    .box
    {
        background-color: beige;
        margin-top: 10px;
        padding: 10px;
        border:1px solid #ccc;
        border-radius: 5px;
        margin-bottom: 10px;
        .line
        {
            span
            {
                padding:2px;
                border-radius: 5px;
                background-color: #000;
                color:white;
                padding-left: 5px;
                padding-right: 5px;
                margin-left: 5px;
            }
        }
    }
    
}
